import React from 'react'
import { RedoOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import {
  getAdvisorViewStudentFilter,
  makeAdvisorStudentRoleUuId,
} from '../../../../../selectors/innovative/advisorView.selector';
import { useSelector, useDispatch } from "react-redux";
import { fetchAdvisorMainData } from '../../../../../actions/innovative/advisorView.action';

const IndividualReload = ({ studentId }) => {

  const dispatch = useDispatch();

  const advisorViewStudentFilter = useSelector(getAdvisorViewStudentFilter);
  const advisorStudentRoleUuId = useSelector(makeAdvisorStudentRoleUuId);

  const handleReload = () => {
    const payload = {
      user_id: studentId,
      state: advisorViewStudentFilter.state,
      enStart: advisorViewStudentFilter.enStart,
      enEnd: advisorViewStudentFilter.enEnd,
      terms: advisorViewStudentFilter.terms,
      role_uuid: advisorStudentRoleUuId,
    };

    dispatch(fetchAdvisorMainData(payload))
  };
  return (
    <div
      className="advisor-studentReload"
    >
      <Button
        icon={<RedoOutlined />}
        style={{ width: '100px' }}
        onClick={handleReload}
      />
    </div>
  )
}

export default IndividualReload;
