import { Button, Card,  Modal, Row, Space,} from 'antd';
import React, { useState } from 'react';
import canvasLogo from "../../../assets/images/canvas_logo.svg";
import NotificationMessageComponent from '../notificationManagement/notificationMessageComponent';
import { sendIndividualNotification } from '../../../actions/notificationManagement/notification.action';
import { useDispatch } from 'react-redux';

const SendMessageModal = ({
  isMessageModalOpen,
  setIsMessageModalOpen,
  to,
  setSendMessageTo,
}) => {
  const [sub, setSub] = useState('');
  const [message, setMessage] = useState('');
  const [errors, setErrors] = useState({ subject: '', message: '' });
  const dispatch = useDispatch();

  const onCancel = () => {
    setIsMessageModalOpen(false);
    setSendMessageTo({});
  }

  const sendInduvidualMessage = () => {
    if (validateInputs()) {
      const payload = {
        recipients: [String(to.student_id)],
        subject: sub,
        body: message
      }
      dispatch(sendIndividualNotification(payload));
      onCancel()
    }
  }

  const validateInputs = () => {
    const newErrors = { subject: '', message: '' };
    let isValid = true;

    if (!sub) {
      newErrors.subject = '* Subject is required';
      isValid = false;
    }
    if (!message) {
      newErrors.message = '* Message is required';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  return (
    <Modal
      centered
      className='send-message-notification-modal'
      visible={isMessageModalOpen}
      destroyOnClose
      title={<span>
        Send Message to {to.student_name}
      </span>}
      onOk={() => { }}
      onCancel={onCancel}
      footer={[
        <div key={0}>
          <Button
            variant="secondary"
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={sendInduvidualMessage}
          >
            Send
          </Button>
        </div>,
      ]}
    >
      <div>Send Message to</div>
      <Space
        direction='column'
      >
        <Card
          className='notification-checkbox-container'
        >
          <Row
            className='notification-checkbox'
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img src={canvasLogo} />
              <span>
                Canvas
              </span>
            </div>
          </Row>
        </Card>
      </Space>
      <NotificationMessageComponent
        setSub={setSub}
        setMessage={setMessage}
        errors={errors}
      />
    </Modal>
  );
};

export default SendMessageModal;
