import React, { useState, useEffect } from "react";
import { Switch, Card, Row, Col, Space, Layout, Button, Select, Typography, Form } from "antd";
import { useDispatch, useSelector } from "react-redux";
import NotificationMessageComponent from "../../common/notificationManagement/notificationMessageComponent";
import { setBreadcrumb, setMenuSelected } from "../../../actions/dashboardManagement/layout.action";
import menuData from "../../layout/dashboardManagement/menuData";
import LayoutFooter from "../../vwDealership/common/layout/LayoutFooter";
import { fetchNotificationSettings, saveNotificationSettings } from "../../../actions/notificationManagement/notification.action";
import { getFetchedNotificationSettingsData, getFetchedNotificationSettingsLoading } from "../../../selectors/notificationManagement/notificationManagement.selector";
import { ExclamationCircleTwoTone } from "@ant-design/icons";
import Loader from "../common/layout/Loader";

const { Title } = Typography;

const NotificationManagement = () => {
  const dispatch = useDispatch();

  const [inactivityNotificationsEnabled, setInactivityNotificationsEnabled] = useState(true);
  const [productAndFeatureNotificationsEnabled, setProductAndFeatureNotificationsEnabled] = useState(true);
  const [reminderFrequency, setReminderFrequency] = useState({});
  const [sub, setSub] = useState();
  const [message, setMessage] = useState();
  const [errors, setErrors] = useState({ subject: '', message: '' });

  const fetchedNotificationSettingsData = useSelector(getFetchedNotificationSettingsData);
  const fetchedNotificationSettingsLoading = useSelector(getFetchedNotificationSettingsLoading);

  const remainderDates = [{ value: 'days', label: 'days' },
  { value: 'two days', label: 'two days' },
  { value: 'week', label: 'week' },
  { value: 'two week', label: 'two week' },
  { value: 'month', label: 'month' },
  { value: 'three months', label: 'three months' }];

  const breadcrumbItems = [
    {
      key: 'admin',
      section: "Admin",
    },
    {
      key: menuData.notificationManagement.key,
      section: menuData.notificationManagement.name,
    },
  ];

  const validateInputs = () => {
    const newErrors = { subject: '', message: '' };
    let isValid = true;

    if (!sub) {
      newErrors.subject = '* Subject is required';
      isValid = false;
    }
    if (!message) {
      newErrors.message = '* Message is required';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const sendInduvidualMessage = () => {
    if (validateInputs()) {
      const payload = {
        inactivity_alert: inactivityNotificationsEnabled,
        send_remainder: reminderFrequency?.value,
        subject: sub,
        message: message,
        release_alert: productAndFeatureNotificationsEnabled
      }
      dispatch(saveNotificationSettings(payload));
    }
  }

  useEffect(() => {
    dispatch(fetchNotificationSettings());
    dispatch(setBreadcrumb(breadcrumbItems));
  }, [])

  useEffect(() => {
    setInactivityNotificationsEnabled(fetchedNotificationSettingsData.inactivity_alert);
    setProductAndFeatureNotificationsEnabled(fetchedNotificationSettingsData.release_alert);
    const setReminder = {
      value: fetchedNotificationSettingsData.send_remainder,
      label: fetchedNotificationSettingsData.send_remainder
    }
    setReminderFrequency(setReminder);
  }, [fetchedNotificationSettingsData]);

  // set menu item
  useEffect(() => {
    dispatch(setMenuSelected(menuData.notificationManagement.key));
  }, [])

  const onInactivityNotificationsToggle = checked => {
    setInactivityNotificationsEnabled(checked)
  };

  const onReminderFrequencyChange = (_, frequent) => {
    setReminderFrequency(frequent);
  }

  const renderInactivityNotificationsContent = () => {
    if (inactivityNotificationsEnabled) {
      return (
        <Card
          className='switch-card'
        >
          <Row>
            <Form.Item colon={false}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <ExclamationCircleTwoTone style={{ fontSize: '24px', marginRight: '8px' }} />
                <span>
                  Enabling this toggle will result in a notification message being sent to the inactive user's Canvas inbox based on the setup frequency.
                </span>
              </div>
            </Form.Item>
          </Row>
          <Row>
            <Form.Item colon={false} label="Send Reminder in every" labelAlign="left">
              <Select
                value={reminderFrequency?.value}
                className="notification-message-frequency"
                onChange={onReminderFrequencyChange}
                options={remainderDates}
              />
            </Form.Item>
          </Row>
          <NotificationMessageComponent
            sub={fetchedNotificationSettingsData.subject}
            message={fetchedNotificationSettingsData.message}
            setMessage={setMessage}
            setSub={setSub}
            errors={errors}
          />
        </Card>
      );
    }
    return null;
  };

  return (
    <>
    <Loader isLoading={fetchedNotificationSettingsLoading}/>
      <div
        className="student-cluster-settings in-container"
      >
        <div>
          <Title level={4}>Notification Management</Title>
        </div>
        <div>
          <Card className="switch-card">
            <Row justify="space-between">
              <Col span={20}>
                <Title level={5}>Inactivity Notifications</Title>
              </Col>
              <Col span={4}>
                <Row justify="end">
                  <Switch
                    checked={inactivityNotificationsEnabled}
                    onChange={onInactivityNotificationsToggle}
                  />
                </Row>
              </Col>
            </Row>
          </Card>
          {renderInactivityNotificationsContent()}
        </div>
        <div>
          <Card className="switch-card">
            <Row justify="space-between">
              <Col span={20}>
                <Title level={5}>Notifications for product and feature releases</Title>
              </Col>
              <Col span={4}>
                <Row justify="end">
                  <Switch
                    checked={productAndFeatureNotificationsEnabled}
                    onChange={setProductAndFeatureNotificationsEnabled}
                    disabled
                  />
                </Row>
              </Col>
            </Row>
          </Card>
        </div>
        <Card className="switch-card">
          <Row justify="end">
            <Button style={{ marginRight: '8px' }}>Cancel</Button>
            <Button onClick={sendInduvidualMessage} type="primary">
              Save
            </Button>
          </Row>
        </Card>
      </div>
    </>
  )
}

export default NotificationManagement;
