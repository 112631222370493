import { combineReducers } from 'redux';
import TodoApp from './todos.reducer';
import EmailApp from './email.reducer';
import ChatApp from './chat.reducer';
import EcommerceApp from './ecommerce.reducer';
import WishlistApp from './wishlist.reducer';
import Filters from './filters.reducer';
import Customizer from './customizer.reducer';
import Authentication from './authentication.reducer';
import SamlAuthentication from './authentication/samlAuthentication.reducer';
import Signup from "./signup.reducer";
import Consumer from "./consumer.reducer";
import innovative from "./innovative";
import innovativeStudent from "./innovative_student";
import dashboardManage from "./dashboardManagement";
import jrDashboardManage from "./jrDashboardManagement";
import vwDealership from "./vwDealership";
import tentacleMeta from "./tentacleMeta.reducer";
import jrGeneric from "./jobReadyGeneric";
import acc from "./acc";
import vetTrack from './vetTrack';
import userManagementReducer from "./userManagement/userManagement.reducer";
import notificationManagementReducer from "./notificationManagement/notificationManagement.reducer";

const reducers = combineReducers({
    TodoApp,
    EmailApp,
    ChatApp,
    data: EcommerceApp,
    WishlistApp,
    filters: Filters,
    Customizer,
    Authentication,
    SamlAuthentication,
    signup: Signup,
    consumer: Consumer,
    innovative,
    innovativeStudent,
    dashboardManage,
    jrDashboardManage,
    vwDealership,
    tentacleMeta,
    jrGeneric,
    acc,
    vetTrack,
    userManagementReducer,
    notificationManagementReducer,
});

export default reducers;