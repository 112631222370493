import {
  UPDATE_ADVISOR_VIEW_VISIBILITY,
  SELECTED_ROLE,
  FETCH_ROLE_LIST,
  FETCH_ROLE_LIST_LOADING,
  ADVISOR_FILTERS,
  ADVISOR_FILTERS_LOADING,
  FETCH_ADVISOR_STUDENT,
  RESET_ADVISOR_STUDENT,
  ADVISOR_STUDENT_LOADING,
  SET_ADVISOR_STUDENT_FILTERS,
  FETCH_ADVISOR_MAIN_DATA,
  ADVISOR_MAIN_DATA_LOADING,
  RESET_ADVISOR_MAIN_DATA,
  FETCH_ADVISOR_VISIBILITY_LOADING,
  FETCH_ADVISOR_VISIBILITY,
  RESET_ADVISOR_STUDENT_FILTERS,
  ADVISOR_TERMS,
  ADVISOR_TERMS_LOADING,
  SET_ROLE_UUID,
  SET_STUDENT_PAGE_NUM,
  SET_STUDENT_SEARCH_VALUE,
  SET_All_COURES_LOADING_VALUE,
} from "../../constant/actionTypes";
import _get from "lodash/get";

const advisorViewReducerDefaultState = {

  advisorView: {
    accessLoading: false,
    access: false,
    isVisible: false,
  },
  roleData: {
    roleItem: []
  },
  roleList: {
    isLoading: false,
    error: false,
    data: {}
  },
  advisorFilters: {
    isLoading: false,
    error: false,
    data: {}
  },
  advisorTerms: {
    isLoading: false,
    error: false,
    data: []
  },
  advisorStudentRoleUuId: {
    role_uuid: "",
  },
  advisorStudentpageNo: {
    pageNo: "1",
  },
  advisorStudentSearchValue: {
    value: "",
  },
  advisorStudents: {
    isLoading: false,
    error: false,
    data: {}
  },
  studentAdvisorFilters: {
    data: {}
  },
  studentData: {
    isLoading: false,
    error: {},
    data: {}
  },
  allCouresLoading: {
    isLoading: false,
  }
};

const advisorViewReducer = (state = advisorViewReducerDefaultState, action) => {
  switch (action.type) {
    case FETCH_ADVISOR_VISIBILITY_LOADING: {
      return {
        ...state,
        advisorView: {
          ...state.advisorView,
          accessLoading: action.payload.isLoading,
        },
      };
    }
    case FETCH_ADVISOR_VISIBILITY: {
      return {
        ...state,
        advisorView: {
          ...state.advisorView,
          access: action.payload.data.status,
        },
      };
    }
    case UPDATE_ADVISOR_VIEW_VISIBILITY: {
      return {
        ...state,
        advisorView: {
          ...state.advisorView,
          isVisible: action.payload.isVisible,
        },
      };
    }
    case SELECTED_ROLE: {
      return {
        ...state,
        roleData: {
          ...state.roleData,
          roleItem: action.payload.data
        }
      };
    }
    case FETCH_ROLE_LIST_LOADING: {
      return {
        ...state,
        roleList: {
          ...state.roleList,
          isLoading: action.payload.isLoading
        }
      };
    }
    case FETCH_ROLE_LIST: {
      return {
        ...state,
        roleList: {
          ...state.roleList,
          ...action.payload
        }
      };
    }
    case ADVISOR_FILTERS_LOADING: {
      return {
        ...state,
        advisorFilters: {
          ...state.advisorFilters,
          isLoading: action.payload.isLoading
        }
      };
    }
    case ADVISOR_FILTERS: {
      return {
        ...state,
        advisorFilters: {
          ...state.advisorFilters,
          ...action.payload
        }
      };
    }
    case ADVISOR_TERMS_LOADING: {
      return {
        ...state,
        advisorTerms: {
          ...state.advisorTerms,
          isLoading: action.payload.isLoading
        }
      };
    }
    case ADVISOR_TERMS: {
      return {
        ...state,
        advisorTerms: {
          ...state.advisorTerms,
          isLoading: action.payload.isLoading,
          error: action.payload.error,
          data: action.payload.data,
        }
      };
    }
    case RESET_ADVISOR_STUDENT_FILTERS: {
      return {
        ...state,
        advisorStudents: {
          ...state.advisorStudents,
          isLoading: false,
          error: false,
          data: {}
        }
      };
    }
    case ADVISOR_STUDENT_LOADING: {
      return {
        ...state,
        advisorStudents: {
          ...state.advisorStudents,
          isLoading: action.payload.isLoading,
        }
      };
    }
    case FETCH_ADVISOR_STUDENT: {
      return {
        ...state,
        advisorStudents: {
          ...state.advisorStudents,
          isLoading: action.payload.isLoading,
          error: action.payload.error,
          data: action.payload.data,
        }
      };
    }
    case RESET_ADVISOR_STUDENT: {
      return {
        ...state,
        advisorStudents: {
          ...state.advisorStudents,
          isLoading: false,
          error: false,
          data: {},
        }
      };
    }
    case SET_ADVISOR_STUDENT_FILTERS: {
      return {
        ...state,
        studentAdvisorFilters: {
          ...state.studentAdvisorFilters,
          data: action.payload.data,
        },
      };
    }
    case ADVISOR_MAIN_DATA_LOADING: {
      return {
        ...state,
        studentData: {
          ...state.studentData,
          isLoading: {
            ...((state.studentData && state.studentData.isLoading) || {}),
            [action.payload.studentId]: true,
          },
          data: {
            ...((state.studentData && state.studentData.data) || {}),
            [action.payload.studentId]: {},
          },
        }
      };
    }
    case FETCH_ADVISOR_MAIN_DATA: {
      const isError = _get(action.payload, "error", false);
      const { user_id, ...data } = _get(action.payload, "data", {});

      const getData = () => {
        if (isError) {
          return { user_id: user_id, error: true };
        }
        return { ...data };
      };

      return {
        ...state,
        studentData: {
          ...state.studentData,
          isLoading: {
            ...((state.studentData && state.studentData.isLoading) || {}),
            [user_id]: false,
          },
          data: {
            ...((state.studentData && state.studentData.data) || {}),
            [user_id]: getData(),
          },
        },
      };
    }
    case SET_ROLE_UUID: {
      return {
        ...state,
        advisorStudentRoleUuId: {
          ...state.advisorStudentDispachPayload,
          role_uuid: action.payload.data.role_uuid
        }
      }
    }
    case SET_STUDENT_SEARCH_VALUE: {
      return {
        ...state,
        advisorStudentSearchValue: {
          ...state.advisorStudentSearchValue,
          value: action.payload.data
        }
      }
    }
    case SET_STUDENT_PAGE_NUM: {
      return {
        ...state,
        advisorStudentpageNo: {
          ...state.advisorStudentpageNo,
          pageNo: action.payload.data.pageNo
        }
      }
    }
    case RESET_ADVISOR_MAIN_DATA: {
      return {
        ...state,
        studentData: {
          isLoading: false,
          error: {},
          data: {}
        }
      };
    }
    case SET_All_COURES_LOADING_VALUE: {
      return {
        ...state,
        allCouresLoading: {
          isLoading: action.payload.data,
        }
      };
    }

    default:
      return state;
  }
};

export default advisorViewReducer;