import React, { useCallback, useEffect, useState, useRef } from 'react';
import { get as _get } from 'lodash';
import { useDispatch, useSelector } from "react-redux";
import StudentAssignmentView from "./StudentAssignmentView";
import { fetchAssignments, fetchStudentsCourses } from "../../../actions/innovative_student/assignment.action";
import { 
    clearAssignmentChartData,
    fetchAssignmentFilterData,
    fetchTeacherStudentAssignmentsChartData,
} from '../../../actions/innovative/assignment.action';
import { useLocation, useParams } from "react-router";
import { fetchStudent } from "../../../actions/innovative_student/student.action";
import { makeInnovativeStudentResponseData } from "../../../selectors/innovative_student/student.selector";
import {
    makeInnovativeAssignmentsLoading,
    makeInnovativeAssignmentsResponseData,
    makeInnovativeOverallOutcomesResponseData,
    makeInnovativeOverallMasteryStatusResponseData,
    makeInnovativeStudentCoursesLoading,
    makeInnovativeStudentCoursesResponseData,
    makeInnovativeAssignmentsComparisonResponseData,
} from "../../../selectors/innovative_student/assignment.selector";
import { setBreadcrumb } from "../../../actions/innovative/layout.action";
import {
    makeActingRoleLoading, makeActingRoleResponseData,
    makeInnovativeMasterSettingByPath,
    makeInnovativeStudentTermsLoading,
    makeInnovativeStudentTermsResponseData,
    makeStudentListLoading,
    makeStudentListResponseData
} from "../../../selectors/innovative_student/commonData.selector";
import {
    getAssignmentGradeCohortData,
    makeInnovativeAssignmentFilterData,
} from "../../../selectors/innovative/assignment.selector";
import { makeInnovativeSettingByPath } from "../../../selectors/innovative_student/commonData.selector";
import {
    checkIfVariableHasValueAndLength,
    getAccessToken,
    queryStringToObject,
} from "../../../utils/general";
import {
    fetchActingRole,
    fetchMasterSettings,
    fetchSettings,
    fetchStudentList,
    fetchStudentsTerms
} from "../../../actions/innovative_student/commonData.action";
import session from "../../../utils/session";
import {
    resolvePropertiesFromSettingArray,
} from "../../../utils/innovative/resolveSettings";
import impressionHandler from "../../../utils/impressionHandler/impressionHandler";
import { ACTING_ROLES as ROLES } from "../../../constant/innovative_student";
import useDidMountEffect from "../../../utils/innovative/customHooks";
import { FILTER_ASSIGNMENT_GROUP } from '../../../utils/innovative/constants';
import ChartDataFetch from '../../innovative/studentProfile/chartDataFetch';
import { toInteger } from 'lodash';
import { getIsApplicationAdmin } from '../../../selectors/innovative/course.selector';
import { resolveCohortVisibility } from '../../../utils/innovative_student/resolveSettings';

const CHART_FETCH_ARRAY_SIZE = 5;
const ADMIN_PATH = "/insights/admin/student/assignments";
const ACCOUNT_MEMBERSHIP = "accountmembership";

const StudentAssignmentProfile = () => {
    const { studentId, courseId, termId } = useParams();
    const control = useRef();

    const innovativeMetaData = session.get(session.keys.innovativeMeta);

    const [actingRoleState, setActingRoleState] = useState("");
    const [selectedStudent, setSelectedStudent] = useState(studentId);
    const [selectedTerm, setSelectedTerm] = useState(termId);
    const [selectedCourse, setSelectedCourse] = useState(courseId);
    const [courseData, setCourseData] = useState(null);
    const [currentTab, setCurrentTab] = useState("tasks");
    const [chartDataController, setChartDataController] = useState(null);
    const [taskTablePagination, setTaskTablePagination] = useState(0);

    const dispatch = useDispatch();
    const search = queryStringToObject(useLocation().search);
    const [breadcrumbKey, setBreadcrumbKey] = useState({
        key: "tasks",
        section: "Tasks",
    });

    const parsedUrl = new URL(window.location.href);
    const path = parsedUrl.pathname;

    // selectors
    const overallMastery = useSelector(makeInnovativeOverallMasteryStatusResponseData);
    const overallOutcomes = useSelector(makeInnovativeOverallOutcomesResponseData);
    const student = useSelector(makeInnovativeStudentResponseData);
    const assignmentReport = useSelector(makeInnovativeAssignmentsResponseData);
    const assignmentReportLoading = useSelector(makeInnovativeAssignmentsLoading);
    const markingScheme = useSelector(makeInnovativeSettingByPath("markingScheme"));
    const colorScheme = useSelector(makeInnovativeSettingByPath("colorScheme"));
    const actingRoleLoading = useSelector(makeActingRoleLoading);
    const actingRole = useSelector(makeActingRoleResponseData);
    const terms = useSelector(makeInnovativeStudentTermsResponseData);
    const termsLoading = useSelector(makeInnovativeStudentTermsLoading);
    const studentsLoading = useSelector(makeStudentListLoading);
    const students = useSelector(makeStudentListResponseData);
    const coursesLoading = useSelector(makeInnovativeStudentCoursesLoading);
    const courses = useSelector(makeInnovativeStudentCoursesResponseData);
    const assignmentFilterData = useSelector(makeInnovativeAssignmentFilterData);
    const cohortData = useSelector(getAssignmentGradeCohortData);
    const assignmentsListComparison = useSelector(makeInnovativeAssignmentsComparisonResponseData);    
    const isApplicationAdminData = useSelector(getIsApplicationAdmin);
    const dashboardManagementData = session.get(_get(session, "keys.dashboardManageMeta", ""));
    const applicationAdmin = _get(dashboardManagementData, "is_admin", false);
    const cohortVisibility = useSelector(makeInnovativeMasterSettingByPath("features.cohortVisibility"));

    const actAs = isApplicationAdminData.role;

    const getRole = ({ actAs, actingRoleState, actingRole }) => {
        if (checkIfVariableHasValueAndLength(actAs)) {
            return actAs.toLowerCase();
        }
        if (checkIfVariableHasValueAndLength(actingRoleState)) {
            return actingRoleState;
        }
        if (checkIfVariableHasValueAndLength(actingRole)) {
            return actingRole;
        }
        return "";
    };

    useEffect(() => {
        setActingRoleState(getRole({ actAs, actingRoleState, actingRole }));
    }, [actAs, actingRoleState, actingRole]);

    const resolveCohortVisible = useCallback(() => {
        if (actingRoleState.length) {
            return resolveCohortVisibility(
                actingRoleState,
                cohortVisibility,
                { compareVal: "role", returnVal: "access" }
            );
        }
    }, [actingRoleState, cohortVisibility]);

    const isCohortVisible = resolveCohortVisible();

    const isAdminDashboardView = () => {
        return (
            actAs.toLowerCase() === ACCOUNT_MEMBERSHIP &&
            path.includes(ADMIN_PATH));
    };

    // This is related to overall outcome & mastery status cohort
    const resolveCellData = useCallback((func, data, setting, propMapping) => {
        return func(data, setting, propMapping);
    }, [overallOutcomes, colorScheme.proficiencyRatingForOutcomes]);


    // mastery status cohort conf
    const masteryResolved = resolveCellData(
        resolvePropertiesFromSettingArray,
        overallMastery,
        colorScheme.masteryStatus,
        [0, "status"]);
    const masteryConfig = {
        chart: {
            height: 200,
            width:300,
            marginLeft: 20
        },
        legend: {
            width: 130,
            align: 'right',
            verticalAlign: 'middle',
            layout: 'horizontal',
        },
        series: [{data:overallMastery}],
        plotOptions: {
            pie: {
                colors: masteryResolved.colors,
                center: [30, 70],
                size: '120px',
                left: '0',
            }
        }
    };


    const resolved = resolveCellData(
        resolvePropertiesFromSettingArray,
        overallOutcomes,
        colorScheme.proficiencyRatingForOutcomes,
        [0, "description"]);

    const outcomeConfig = {
        chart: {
            height: 200,
            width:300,
            marginLeft: 20
        },
        legend: {
            width: 130,
            align: 'right',
            verticalAlign: 'middle',
            layout: 'horizontal',
        },
        series: [{data:overallOutcomes}],
        plotOptions: {
            pie: {
                colors: resolved.colors,
                center: [30, 70],
                size: '120px',
                left: '0',
            }
        }
    };

    const chartOptions = {
        legend: {
            align: "right",
            verticalAlign: "middle",
            layout: "horizontal",
        },
    };

    const sideChartsConfigs = [
        { title: "Overall Task Grades" },
        { title: "Overall Task Submissions" },
    ];

    const resetTabs = () => {
        setCurrentTab("tasks");
        setBreadcrumbKey({
            key: "tasks",
            section: "Tasks",
        })

    }

    const termChange = (e) => {
        resetTabs();
        setSelectedCourse(null);
        setSelectedTerm([e]);
    }

    const studentChange = (e) => {
        resetTabs();
        setSelectedTerm(null);
        setSelectedCourse(null);
        setSelectedStudent(e);
    }

    const courseChange = (e) => {
        resetTabs();
        setSelectedCourse(e);
    }

    const { filterAssignmentGroups } = assignmentFilterData;
    const groupIds = filterAssignmentGroups.map(item => item.id);

    useEffect(() => {
        if(search.current){
            setBreadcrumbData(search.current);
        }
    }, [search.current])

    useEffect(() => {
        dispatch(fetchActingRole());
        dispatch(fetchMasterSettings());
    }, []);

    useEffect(() => {
        if(actingRole){
            // check the user's acting role and initiate fetch calls
            if (ROLES.student.includes(actingRole)){
                // Set impression data
                impressionHandler.sendImpressionData("insights_student_tasks")

                return setSelectedStudent(session.get([
                    session.keys.innovativeMeta,
                    "token"
                ]));
            }else if (ROLES.parent.includes(actingRole)){
                // Set impression data
                impressionHandler.sendImpressionData("insights_parent_tasks")

                return dispatch(fetchStudentList());
            }
        }
    }, [actingRole])


    useEffect(() => {
        // Fetch terms after selecting a student
        if (selectedStudent && toInteger(studentId) === toInteger(selectedStudent)) {
            dispatch(fetchStudentsTerms({
                "student_id": studentId,
                "is_admin": applicationAdmin,
            }));
            dispatch(fetchStudent({
                requestParams: {
                    "student_id": studentId,
                    "is_admin":applicationAdmin,
                }
            }));
        }
    }, [selectedStudent]);

    useEffect(() => {
        // Fetch courses after selecting a term
        if(selectedTerm){
            dispatch(fetchStudentsCourses({
                "student_id": studentId,
                "term_ids": selectedTerm,
                "is_admin": applicationAdmin,
            }));
        }
    }, [selectedTerm])

    useEffect(() => {
        // Fetch report data after selecting a course
        if(selectedCourse){
            dispatch(fetchSettings({
                requestParams: {
                    "subject_id": selectedCourse,
                }
            }));

        }
    }, [selectedCourse])

    useEffect(() => {
        if(courses && selectedCourse){
            setCourseData(courses.find(course => course.course_id === selectedCourse))
        }
    }, [selectedCourse, courses])


    // track first update and prevent it
    useEffect(() => {
        if(courses && courses[0]){
            if(selectedTerm !== termId){
                setSelectedCourse(courses[0].course_id)
            }
        }
    }, [courses])

    useDidMountEffect(() => {
        // set first term as selected
        if(terms && terms[0] && selectedTerm == null){
            setSelectedTerm([terms[0]._id])
        }
    }, [terms]);

    const getSearchParams = () => {
        return `?token=${innovativeMetaData.token}&t=${innovativeMetaData.t}&access=${getAccessToken()}&actAs=${isApplicationAdminData.role}`;
    };

    // setting breadcrumb
    useEffect(() => {
        if (student && courseData) {
            const breadCrumb = [
                {
                    key: "home",
                    section: "Home",
                    href: isAdminDashboardView() ? `/insights/admin/student/profile` : `/insights/student/profile`,
                    state: { studentId, courseId, termId },
                    searchQuery: getSearchParams(),
                },
                {
                    key: "student",
                    section: student["student_name"],
                    href: isAdminDashboardView() ? `/insights/admin/student/profile` : `/insights/student/profile`,
                    state: { studentId, courseId, termId },
                    searchQuery: getSearchParams(),
                },
                {
                    key: "course",
                    section: courseData["course_name"],
                },
                breadcrumbKey,
            ];
            dispatch(setBreadcrumb(breadCrumb));
        }
    }, [student, breadcrumbKey, courseData]);

    useEffect(() => {
        abortCallsClearChartData();
        selectedCourse && dispatch(fetchAssignmentFilterData({
            requestParams: {
                course_id: selectedCourse,
                student_id: studentId,
                is_admin: applicationAdmin,
            },
        }));
    }, [selectedCourse]);


    useEffect(() => {
        if (!groupIds.length) return;

        abortCallsClearChartData();
        dispatch(fetchAssignments({
            requestParams: {
                course_id: selectedCourse,
                student_id: studentId,
                filterValuesOfAssignments: groupIds,
                filterType: FILTER_ASSIGNMENT_GROUP,
                is_admin: applicationAdmin,
            }
        }));

    }, [JSON.stringify(groupIds)], selectedCourse);

    const setBreadcrumbData = (key) => {
        const findData = () => {
            switch (key) {
                case "outcomes":
                    return {
                        key: "outcomes",
                        section: "Outcomes",
                    }
                case "tasks":
                default:
                    return {
                        key: "tasks",
                        section: "Tasks",
                    }
            }
        }
        setBreadcrumbKey(findData())
    }

    const applyFilter = (type, values) => {
        dispatch(fetchAssignments({
            requestParams: {
                course_id: selectedCourse,
                student_id: studentId,
                filterValuesOfAssignments: values,
                filterType: type,
                is_admin: applicationAdmin,
            },
        }));
    };

    useEffect(() => {
        // initiate fetching chart data after main report data loaded
        const assignmentIds = assignmentsListComparison.map(assignment => assignment.assignment_id);
        if (
            control.current &&
            assignmentsListComparison.length &&
            !assignmentReportLoading &&
            isCohortVisible &&
            assignmentIds.length
        ) {
            fetchChartData(assignmentIds);
        }
    }, [
        control.current,
        assignmentsListComparison,
        assignmentReportLoading,
        isCohortVisible,
    ]);

    useEffect(() => {
        if (cohortData.length && chartDataController) {
            chartDataController.validateArray(cohortData.map(data => data.assignmentId), taskTablePagination);
        }
    }, [cohortData, taskTablePagination]);

    useEffect(() => {
        // create abort controller instance for abort fetch calls
        control.current = new AbortController();
        dispatch(clearAssignmentChartData());

        return () => {
            control.current.abort();
        };
    }, []);

    const fetchChartData = async assignmentIds => {
        // create object of data handler and initiate chart data fetching
        const chartController = new ChartDataFetch(CHART_FETCH_ARRAY_SIZE, assignmentIds, dispatchChartData);
        setChartDataController(chartController);
        chartController.initiateFetch();
    }

    const dispatchChartData = assignmentId => {
        const data = {
            "course_ids": courseId,
            "student_id": studentId,
            "filterValuesOfAssignments": groupIds,
            "filterType": "assignment_group",
            "assignment_id": assignmentId,
        };

        dispatch(fetchTeacherStudentAssignmentsChartData(data, control.current.signal));
    }

    const abortChartFetchCalls = () => {
        control.current.abort();
        control.current = new AbortController();
    }

    const abortCallsClearChartData = () => {
        // remove ongoing chart data fetching calls
        control.current && abortChartFetchCalls();
        // clear current fetched chart data
        dispatch(clearAssignmentChartData());
    }

    return <StudentAssignmentView
        assignmentsReport={assignmentReport}
        assignmentsLoading={assignmentReportLoading || actingRoleLoading}
        student={student}
        courseId={selectedCourse}
        chartOptions={chartOptions}
        sideChartsConfigs={sideChartsConfigs}
        currentViewTab={search.current}
        outcomeEnabled={markingScheme.activatingItems.outcomes}
        outcomeConfig={outcomeConfig}
        masteryConfig={masteryConfig}
        setBreadcrumb={setBreadcrumbData}
        studentFilterConfig={{dataList:students, isLoading:studentsLoading, onChange:studentChange, selected:selectedStudent}}
        termFilterConfig={{dataList:terms, isLoading:termsLoading, onChange:termChange, selected:selectedTerm}}
        courseFilterConfig={{dataList:courses, isLoading:coursesLoading, onChange:courseChange, selected:selectedCourse}}
        actingRole={actingRole}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        applyFilter={applyFilter}
        setTaskTablePagination={setTaskTablePagination}
    />
};

export default StudentAssignmentProfile;
