import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  getDashboardFiltersFetchLoading,
  getOverviewChartsLoading,
  getOverviewStudentsLoading,
  getAcademicCardsLoading,
  getAcademicOverviewLoading,
  getAcademicStudentsLoading,
  getComplianceCardsLoading,
  getComplianceOverviewLoading,
  getComplianceStudentsLoading,
  getFinancialCardsLoading,
  getFinancialOverviewLoading,
  getFinancialStudentsLoading,
} from '../../../../selectors/vetTrack/analysis.selector';

import VetTrackSidebar from '../common/layout/Sidebar';
import VetTrackInnerContent from '../common/layout/InnerContent';
import FilterContainer from './partials/filters';
import TabSection from './partials/tabSection/TabSection';
import Loader from '../common/layout/Loader';

import { subSections } from '../../../../constant/vetTrack/analysis';

const VetTrackHome = () => {
  const [activeTab, setActiveTab] = useState(null);

  // selectors
  const sidebarDataFetchLoading = useSelector(getDashboardFiltersFetchLoading);

  // selectors
  const ovChartLoading = useSelector(getOverviewChartsLoading);
  const ovStudentLoading = useSelector(getOverviewStudentsLoading);

  const acCardLoading = useSelector(getAcademicCardsLoading);
  const acStudentLoading = useSelector(getAcademicStudentsLoading);
  const acOverviewLoading = useSelector(getAcademicOverviewLoading);

  const cmCardLoading = useSelector(getComplianceCardsLoading);
  const cmStudentLoading = useSelector(getComplianceStudentsLoading);
  const cmOverviewLoading = useSelector(getComplianceOverviewLoading);

  const fiCardLoading = useSelector(getFinancialCardsLoading);
  const fiStudentLoading = useSelector(getFinancialStudentsLoading);
  const fiOverviewLoading = useSelector(getFinancialOverviewLoading);

  const barLoading =
    ovChartLoading ||
    ovStudentLoading ||
    acCardLoading ||
    acStudentLoading ||
    acOverviewLoading ||
    cmCardLoading ||
    cmStudentLoading ||
    cmOverviewLoading ||
    fiCardLoading ||
    fiStudentLoading ||
    fiOverviewLoading;

  // effects
  useEffect(() => {
    setActiveTab(subSections.overview.key);
  }, []);

  const onTabChange = key => {
    setActiveTab(key);
  };

  return (
    <>
      <Loader isLoading={sidebarDataFetchLoading} />
      <VetTrackSidebar>
        <div className="filter-section-container">
          <FilterContainer />
        </div>
      </VetTrackSidebar>
      <VetTrackInnerContent>
        <TabSection
          tabConfig={{ activeTab, setActiveTab: onTabChange }}
          barDisabled={barLoading}
        />
      </VetTrackInnerContent>
    </>
  );
};

export default VetTrackHome;
