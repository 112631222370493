import {
  SENDING_INDIVIDUAL_NOTIFICATION,
  SENT_INDIVIDUAL_NOTIFICATION,
  SAVING_NOTIFICATION_SETTINGS,
  SAVED_NOTIFICATION_SETTINGS,
  FETCHING_NOTIFICATION_SETTINGS,
  FETCHED_NOTIFICATION_SETTINGS,
} from "../../constant/actionTypes";

const notificationManagementDefaultState = {
  sendNotification: {
    isLoading: false,
    error: false,
    data: null,
  },
  notificationSettings: {
    isLoading: false,
    error: false,
    data: null,
  },
  fetchNotificationSettings: {
    isLoading: false,
    error: false,
    data: null,
  },
};

const notificationManagementReducer = (
  state = notificationManagementDefaultState,
  action
) => {
  switch (action.type) {
    case SENDING_INDIVIDUAL_NOTIFICATION:
      return {
        ...state,
        sendNotification: {
          isLoading: true,
          error: false,
          data: null,
        },
      };
    case SENT_INDIVIDUAL_NOTIFICATION:
      return {
        ...state,
        sendNotification: {
          isLoading: false,
          error: !!action.payload.error,
          data: action.payload.data || null,
        },
      };
    case SAVING_NOTIFICATION_SETTINGS:
      return {
        ...state,
        notificationSettings: {
          isLoading: true,
          error: false,
          data: null,
        },
      };
    case SAVED_NOTIFICATION_SETTINGS:
      return {
        ...state,
        notificationSettings: {
          isLoading: false,
          error: !!action.payload.error,
          data: action.payload.data || null,
        },
      };
    case FETCHING_NOTIFICATION_SETTINGS:
      return {
        ...state,
        fetchNotificationSettings: {
          isLoading: true,
          error: false,
          data: null,
        },
      };
    case FETCHED_NOTIFICATION_SETTINGS:
      return {
        ...state,
        fetchNotificationSettings: {
          isLoading: false,
          error: !!action.payload.error,
          data: action.payload.data || null,
        },
      };

    default:
      return state;
  }
};

export default notificationManagementReducer;
