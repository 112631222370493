import {
    DotChartOutlined,
    BarChartOutlined,
    DashboardOutlined,
    GlobalOutlined,
    SearchOutlined,
    LockOutlined,
    UserOutlined,
    BellOutlined,
} from "@ant-design/icons";

export default {
    // main menu
    // For SubMenu Items use the following format for the key 
    // {mainMenuItem.key}.{subMenuItem.key}
    manageDashboard: {
        name: "Manage Dashboards",
        key: "dashboard",
        icon: DashboardOutlined,
        url: "/tenant-admin/dashboard/manage",
    },
    stats: {
        name: "Stats",
        key: "stats",
        icon: BarChartOutlined,
        url: "/tenant-admin/stats",
    },
    credentialManagement: {
        name: "Credential Management",
        key: "credentialManagement",
        icon: SearchOutlined,
        rotateIcon: true,
        rotate: 90,
        url: "/tenant-admin/dashboard/credentialManagement",
    },
    globalConfigurations: {
        name: "Global Configurations",
        key: "globalConfig",
        icon: GlobalOutlined,
    },
    studentClusterAnalysis: {
        name: 'Student Cluster Analyzer',
        key: 'studentClusterAnalyzer',
        icon: DotChartOutlined,
        url: "/tenant-admin/student-cluster-analysis",
     },
    markingScheme: {
        name: "Marking Scheme",
        key: "globalConfig.markingScheme",
        url: "/tenant-admin/global-configurations/marking-scheme"
    },
    colorScheme: {
        name: "Color Scheme",
        key: "globalConfig.colorScheme",
        url: "/tenant-admin/global-configurations/color-scheme"
    },
    conditionalColors: {
        name: "Conditional Colors",
        key: "globalConfig.conditionalColors",
        url: "/tenant-admin/global-configurations/conditional-colors"
    },
    statsDataView: {
        name: "Stats Data View",
        key: "statsDataView",
        url: "/tenant-admin/stats/data-view",
    },
    statsDataViewSales: {
        name: "Stats Data View",
        key: "statsDataViewSales",
        url: "/sales-admin/stats/data-view",
    },
    adminCenter: {
        name: "Admin Center",
        key: "adminCenter",
        icon: BarChartOutlined,
    },
    manageDataSource: {
        name: "Data Source Management",
        key: "adminCenter.dataSource",
        url: "/tenant-admin/data-source/manage",
    },
    createDataSource: {
        name: "Add Data Source",
        key: "adminCenter.createDataSource",
        url: "/tenant-admin/data-source/create",
    },
    manageUser: {
        name: "User Management",
        key: "userManage",
        icon: UserOutlined,
    },
    userRoles: {
        name: "Roles",
        key: "userManage.roles",
        url: "/tenant-admin/manage-users/roles",
    },
    userUsers: {
        name: "Users",
        key: "userManage.users",
        url: "/tenant-admin/manage-users/users",
    },
    addGroup:{
        name: "Add Group",
        key: "userManage.addGroup",
        url: "/tenant-admin/manage-users/add-group",
    },
    viewRole:{
        name: "View Role",
        key: "userManage.addGroup",
        url: "/tenant-admin/manage-users/view-role",
    },
    details:{
        name: "Details",
        key: "details",
    },
    permission:{
        name: "Permission",
        key: "permission",
    },
    notificationManagement: {
        name: "Notification Management",
        key: "notificationManagement",
        icon: BellOutlined,
        url: "/tenant-admin/manageNotifications",
    },
    userOverview: {
        name: "Overview",
        key: "adminCenter.user.overview",
        url: "/tenant-admin/user/overview",
    },
    userImport: {
        name: "User Import",
        key: "adminCenter.user.import",
        url: "/tenant-admin/user/import",
    },
    createUserImport: {
        name: "Create User Import",
        key: "adminCenter.user.createImport",
        url: "/tenant-admin/user/import/create",
    },
    userLiveConnect: {
        name: "Live Connect",
        key: "adminCenter.user.liveConnect",
        url: "/tenant-admin/user/live-connect",
    },
    createLiveConnect: {
        name: "Add Live Connect",
        key: "adminCenter.user.addLiveConnect",
        url: "/tenant-admin/user/live-connect/create",
    },

    // sales menu
    manageTenantAdmin: {
        name: "Tenant Admins",
        key: "admins",
        icon: DashboardOutlined,
        url: "/sales-admin/admin/manage",
    },

    // dashboard config menu
    dashboardConfigurations: {
        name: "Dashboard Configuration",
        key: "config",
        icon: DashboardOutlined,
        url: "/tenant-admin/settings/config",
    },
    shareOptions: {
        name: "Share Options",
        key: "shareOptions",
        icon: BarChartOutlined,
        url: "/tenant-admin/settings/share",
    },
    dataSourceConfig: {
        name: "Data Source Config",
        key: "dataSourceConfig",
        icon: BarChartOutlined,
        url: "/tenant-admin/settings/data-source",
    },
    dataPrivacyManagement: {
        name: 'Data Privacy Management',
        key: 'dataPrivacyManagement',
        icon: LockOutlined,
        url: "/tenant-admin/data-privacy-managemant",
    },

    myDashboard: {
        url: "/components/widgets/mydashboards"
    },
}