
import React, { useEffect, memo } from "react";
import { useWebSocket } from "../innovative/useWebSocket";
import { get } from "lodash";
import session from "../session";

const WebSocketConnection = ({dashboardData}) => {
  
const schoolId = get(dashboardData, "[0].school", "");
const userID = get(dashboardData, "[0].commonId", "");

  const chartDataWebSocketConnection = useWebSocket();

  useEffect(() => {
    if (schoolId && userID) {
      chartDataWebSocketConnection.createConnection(
        `${process.env.REACT_APP_SOCKET_CONNECTION_URL}?authToken=${session.getAuthToken()}&platform=canvas`
      );
    }
  }, [schoolId, userID]);

  return <></>
}

export default memo(WebSocketConnection);

